import React from 'react'
import Layout from '../components/Layout'

const Definition = ({ location }) => {
  return (
    <Layout location={location} lang={'en'}>
      <section id="one">
        <header className="major">
          <h2>Family music stimulation</h2>
        </header>
        <p>
          The <strong>family music stimulation sessions</strong> are mainly divided into <strong>two age groups</strong> to better suit the needs of each developmental stage:
        </p>

        <ul>
          <li>From 4 months to 12 months</li>
          <li>From 1 to 3 years</li>
        </ul>

        <p>
          During the first years of life, the main form of communication for infants is through sound. For this reason, musical stimulation is essential to promote their development, helping them discover themselves, their environment, and their own voice. Additionally, it allows children to explore their relationship with their parents, their body, listening, imitation, creativity, and the knowledge of instruments.
        </p>
        <p>
          During the sessions, a space will be created to share and strengthen the bond between parents and children through musical experiences. A <strong>comprehensive sensory stimulation</strong> of the child will take place through songs, sounds, various materials, instruments, listening, movement, dances, lap games, improvisation, and relaxation.
        </p>
        <p>
          Additionally, we offer a family music option with an introduction to English. In this modality, during part of the session, <strong>Kate</strong>, <strong>the Little Bunny</strong>, a friendly English bunny, will accompany us to teach songs in English, offering children their <strong>first immersion into the language</strong> in a natural and fun way.
        </p>
        <p>
          The sessions can be held <strong>weekly</strong>, <strong>bi-weekly</strong>, or as <strong>one-time workshops</strong>, to adapt to the needs of each family and provide a rich musical experience.
        </p>
      </section>
      <section id="two">
        <h2>Gloals</h2>

        <ul>
          <li>To enjoy the proposals presented as a family.</li>
          <li>To promote attention, concentration and memory.</li>
          <li>To explore the different instruments and materials presented and the sound they generate.</li>
          <li>Encourage hand-eye coordination.</li>
          <li>Encourage gross and fine motor skills.</li>
          <li>Stimulate the child's psychomotor skills.</li>
          <li>Synchronise movements to an external source (piece of music).</li>
          <li>Strengthen self-esteem, self-confidence and self-confidence.</li>
          <li>Strengthen the bond between family and child.</li>
          <li>Potentiate children's behavioural, social and communicative skills.</li>
          <li>Promoting language learning: Encouraging the learning and understanding of English through songs, games, and musical activities, fostering auditory comprehension and exposure to a second language.</li>
        </ul>

      </section>
      <section id="three">
        <h2>Upcoming Events</h2>
        <p>
          To check the upcoming music stimulation workshops,
          <a
            className="button special small"
            style={{ marginLeft: '15px' }}
            href='/events'
          >
            click here!
          </a>
        </p>
      </section>
    </Layout>
  )
};

export default Definition;
